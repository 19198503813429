import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import Slider1 from '../assets/images/slider/slider1.jpeg'
// import Slider2 from '../assets/images/slider/slider2.jpeg'
// import Slider3 from '../assets/images/slider/slider3.jpeg'
// import Slider4 from '../assets/images/slider/slider4.jpeg'
// import Slider5 from '../assets/images/slider/slider5.jpeg'
// import Slider6 from '../assets/images/slider/slider6.jpeg'


const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true
  };

  const data = [
    require('../assets/images/slider/slider1.jpeg'),
    require('../assets/images/slider/slider2.png'),
    require('../assets/images/slider/slider3.png'),
    require('../assets/images/slider/slider4.png'),
    require('../assets/images/slider/slider5.jpg'),
    require('../assets/images/slider/slider6.png'),
  ];
  const data1 = [
    require('../assets/images/slider/slider7.png'),
    require('../assets/images/slider/slider8.png'),
    require('../assets/images/slider/slider9.png'),
    require('../assets/images/slider/slider10.jpeg'),
    require('../assets/images/slider/slider11.png'),
    require('../assets/images/slider/slider12.jpeg'),
  ];
  if(window.innerWidth<=450){
    return (
      <div className="slider-container" style={{width:"100%"}}>
        <Slider {...settings}>
          {
            data1.map((d, v) => {
              return (<div key={v}>
                <img src={d} alt="Slide 1" />
              </div>)
            })
          }
        </Slider>
      </div>
    );
  }
  return (
    <div className="slider-container" style={{width:"100%"}}>
      <Slider {...settings}>
        {
          data.map((d, v) => {
            return (<div key={v}>
              <img src={d} alt="Slide 1" />
            </div>)
          })
        }
      </Slider>
    </div>
  );
};



const HomeSlider = () => {
  const whatDevice2 = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // useEffect(() => {
  //   const iframeYT = document.querySelector("iframe");
  //   const style = document.createElement("style");
  //   style.textContent = `
  //     .ytp-chrome-top.ytp-show-cards-title,
  //       .ytp-gradient-top,
  //       .ytp-gradient-bottom {
  //         display: none;
  //       }
  //       a.ytp-watermark.yt-uix-sessionlink.ytp-watermark-small {
  //         display: none;
  //       }
  //   `;
  //   console.log(iframeYT);
  //   // iframeYT?.contentDocument.head.appendChild(style);
  //   // console.dir(iframeYT.contentDocument);
  // }, [])

  return (
    <section className="main-banner-st ">
      <div className="container">
        <div className="row">

          <ImageSlider />

          {/* <ReactPlayer
            className='react-player'
            url='https://drive.google.com/file/d/1zX4PIJvco4uVmZRExcZT1nvd0FoSNpFH/view'
            width='100%'
            height='100%'
          /> */}
          {/* <video
            style={{
              width: "100%",
              height: "106vh",
              position: "absolute",
              top: "56px",
              left: 0,
              objectFit: "fill",
            }}
            className='SliderVideo' muted autoPlay loop controls={false}

            src={whatDevice2 ? 'video/iphoneVideo.webm' : "https://madalphadesigners.com/admin/public/storage/images/0012%20(2).mp4"} type={!whatDevice2 ? "video/mp4" : "video/webm"}
          >
          </video> */}


          {/* <YouTube
            videoId='7lewWOfzDLs'
            id='homeSlider'
            opts={{
              playerVars: {
                autoplay: 1,
                controls: 0,
                rel: 0,
                showinfo: 0,
                mute: 1,
                loop: 1,
                disablekb:0,


              }
            }}
            onReady={async (d) => {
              // await d.target
              console.log(d);
            }}


          /> */}


          {/* <ReactPlayer url='https://www.youtube.com/watch?v=7lewWOfzDLs'
            controls={false}
            loop={true}
            muted={true}
            onReady={(d) => {
              console.log(d);
              const iframeYT = document.querySelector("iframe");
              const style = document.createElement("style");
              style.textContent = `
                .ytp-chrome-top.ytp-show-cards-title,
                  .ytp-gradient-top,
                  .ytp-gradient-bottom {
                    display: none;
                  }
                  a.ytp-watermark.yt-uix-sessionlink.ytp-watermark-small {
                    display: none;
                  }
              `;
              if (iframeYT.contentDocument) {
                iframeYT.contentDocument.head.appendChild(style);
              } else {
                if (iframeYT.contentWindow.document) {
                  iframeYT?.contentWindow?.document?.head?.appendChild(style);
                }
              }
            }}
            width={'100%'}
            height={'109vh'}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            playing={true}
            style={{
              width: '100%',
              position: 'absolute',
              height: '109vh',
              top: 0,
              left: 0
            }}

          />
 */}


        </div>
      </div>
    </section>
  )
}

export default HomeSlider